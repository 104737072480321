import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Sobre mí`}</h2>
    <p>{`Doble técnico superior en `}<strong parentName="p">{`Desarrollo de Aplicaciones Web`}</strong>{` y `}<strong parentName="p">{`Desarrollo de Aplicaciones Multiplataforma`}</strong>{`, con experiencia laboral en Netytec `}</p>
    <p>{`como `}<strong parentName="p">{`diseñador de páginas web`}</strong>{` en `}<strong parentName="p">{`Wordpress`}</strong>{` y su mantenimiento, en föra forest technologies como `}<strong parentName="p">{`desarrollador de aplicaciones móviles`}</strong>{` basadas en `}<strong parentName="p">{`Java`}</strong>{` `}</p>
    <p>{`y desarrollo web en `}<strong parentName="p">{`Javascript`}</strong>{` y `}<strong parentName="p">{`NodeJS`}</strong>{` y en Codesian Software Tech como `}<strong parentName="p">{`diseñador de páginas web`}</strong>{` en `}<strong parentName="p">{`Wordpress`}</strong>{` y `}<strong parentName="p">{`Drupal`}</strong>{`. `}</p>
    <p>{`Participación en procesos de optimización de cálculo, desarrollo de librerías en C# y C++ y visualización de cartografía en páginas web utilizando OpenLayers.js`}</p>
    <p>{`Actualmente enfocado en la creación y mantenimiento de entornos Moodle, así como plugins personalizados y gestión de sistema de soporte como ténico de desarrollo en partner ISYC. `}</p>
    <p>{`Gran capacidad de trabajo en equipo y rápido aprendizaje, realización de proyectos en grupo y máxima motivación.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      