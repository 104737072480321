import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Proyectos`}</h2>
    <ProjectCard title="Memories application" link="https://memories579.netlify.app/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
This project was built with React and NodeJS as backend; MongoDB + Express.
    </ProjectCard>
    <ProjectCard title="Isaba web" link="https://www.isaba.com" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
Web made with Wordpress and Elementor.
    </ProjectCard>
    <ProjectCard title="Notes app" link="https://github.com/eduardo579/ProyectoNotas" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
A course project made with Flutter.
    </ProjectCard>
    <ProjectCard title="Ecommerce template site" link="https://github.com/eduardo579/Escaparate_virtual" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
Small web application written in Javascript with field validation.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      